import React from 'react'
import Seo from "../components/seo"
import {
  RecruitLayout,
  RecruitHeroImage,
  RecruitBg,
  RecruitNews,
  RecruitMessage,
  RecruitWorks,
  RecruitVoice,
  RecruitCulture,
  RecruitInformation,
  RecruitEntry,
  RecruitDiscover,
  RecruitBnr,
} from '../components/Recruit/index'
import * as styles from "../styles/RecruitTop.module.scss";

const Recruit = ( ) => {

  return(
    <RecruitLayout className={`recruit`} pageName="recruit" title="採用情報">
      <Seo
        lang="ja"
        title={`採用情報`}
        description="一緒に働く仲間を募集しています！やる気があれば未経験でも大歓迎です。"
      />
      <RecruitHeroImage/>
      <div className={styles.RecruitTop}>
        <RecruitBg/>
        <section id="news">
          <RecruitNews/>
        </section>
        <section id="message">
          <RecruitMessage/>
        </section>
        <section id="works">
          <RecruitWorks/>
        </section>
        <section id="voice">
          <RecruitVoice/>
        </section>
        <section id="culture">
          <RecruitCulture/>
        </section>
        <section id="information">
          <RecruitInformation/>
        </section>
        <section id="entry">
          <RecruitEntry/>
        </section>
        <section id="discover">
          <RecruitDiscover/>
        </section>
        <section id="bnr">
          <RecruitBnr/>
        </section>
      </div>
    </RecruitLayout>
  )
}
export default Recruit